import styled from "styled-components"

export const Bar = styled.div`
  margin-top: 50px;
  width: 100%;
  p {
    color: #fff;
    font-size: 20px;
    :last-child {
      text-align: right;
    }

    @media (max-width: 1300px) {
      font-size: 18px;
    }
  }
  svg {
    padding: 20px 0;
    defs stop {
      transition: all 0.3s;
    }
  }

  @media (max-width: 1300px) {
    margin-top: 30px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`
