import { useCallback, useEffect, useState } from "react"
import { config, useChain, useSpring, useSpringRef } from "react-spring"

export const useAnimation = props => {
  const [isNotInitialEntry, setIsNotInitialEntry] = useState()

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsNotInitialEntry(localStorage.getItem("isInitialEntry"))
    }
  }, [])

  useEffect(() => {
    if (!isNotInitialEntry) {
      document.querySelector("html").style.overflow = "hidden"
    } else {
      document.querySelector("html").style.overflow = "auto"
    }
  }, [isNotInitialEntry])

  const onAnimationFinish = useCallback(() => {
    document.querySelector("html").style.overflow = "auto"

    if (!isNotInitialEntry) {
      localStorage.setItem("isInitialEntry", true)
    }
  }, [isNotInitialEntry])

  const captionRef = useSpringRef()
  const captionStyles = useSpring({
    ref: captionRef,
    config: config.stiff,
    from: {
      opacity: isNotInitialEntry ? 1 : 0,
      left: isNotInitialEntry ? 0 : -50,
    },
    to: {
      opacity: 1,
      left: 0,
    },
  })

  const titleRef = useSpringRef()
  const titleStyles = useSpring({
    ref: titleRef,
    config: config.stiff,
    from: {
      opacity: isNotInitialEntry ? 1 : 0,
      left: isNotInitialEntry ? 0 : -50,
    },
    to: {
      opacity: 1,
      left: 0,
    },
    delay: 700,
  })

  const subitleRef = useSpringRef()
  const subtitleStyles = useSpring({
    ref: subitleRef,
    config: config.stiff,
    from: {
      opacity: isNotInitialEntry ? 1 : 0,
      left: isNotInitialEntry ? 0 : -50,
    },
    to: {
      opacity: 1,
      left: 0,
    },
    delay: 1000,
    onRest: () => onAnimationFinish(),
  })

  const svgLineRef = useSpringRef()
  const svgLineStyles = useSpring({
    ref: svgLineRef,
    from: {
      strokeDasharray: isNotInitialEntry ? 0 : props?.lineLength,
      strokeDashoffset: isNotInitialEntry ? 0 : props?.lineLength,
    },
    to: {
      strokeDashoffset: 0,
    },
  })

  const svgArrowRightRef = useSpringRef()
  const svgArrowRightStyles = useSpring({
    ref: svgArrowRightRef,
    from: {
      strokeDasharray: isNotInitialEntry ? 0 : props?.arrowRightLength,
      strokeDashoffset: isNotInitialEntry ? 0 : props?.arrowRightLength,
    },
    to: {
      strokeDashoffset: 0,
    },
  })

  const svgArrowLeftRef = useSpringRef()
  const svgArrowLeftStyles = useSpring({
    ref: svgArrowLeftRef,
    from: {
      strokeDasharray: isNotInitialEntry ? 0 : props?.arrowLeftLength,
      strokeDashoffset: isNotInitialEntry ? 0 : props?.arrowLeftLength,
    },
    to: {
      strokeDashoffset: 0,
    },
  })

  useChain(
    [
      captionRef,
      svgLineRef,
      svgArrowRightRef,
      svgArrowLeftRef,
      titleRef,
      subitleRef,
    ],
    [1.5, 2]
  )

  const animation = {
    captionStyles,
    titleStyles,
    subtitleStyles,
    svgLineStyles,
    svgArrowLeftStyles,
    svgArrowRightStyles,
  }

  return { animation }
}
