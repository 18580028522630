import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Container } from "components/Layout/styled"
import {
  RecognitionDescription,
  RecognitionGrid,
  RecognitionItem,
  RecognitionSection,
  RecognitionTitle,
} from "./styled"

const Recognition = ({ recognition }) => {
  const { sectionTitle, description, clutchLink, images } = recognition || {}

  return (
    <RecognitionSection>
      <Container>
        <RecognitionTitle
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="50"
          data-sal-easing="ease"
        >
          {sectionTitle}
        </RecognitionTitle>

        <RecognitionDescription
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="50"
          data-sal-easing="ease"
        >
          {description}
        </RecognitionDescription>

        <RecognitionGrid>
          {images.map(({ id, gatsbyImageData }) => {
            return (
              <RecognitionItem
                key={id}
                href={clutchLink}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  data-sal="slide-up"
                  data-sal-duration="600"
                  data-sal-delay="50"
                  data-sal-easing="ease"
                >
                  <GatsbyImage
                    image={getImage(gatsbyImageData)}
                    alt="technology"
                  />
                </div>
              </RecognitionItem>
            )
          })}
        </RecognitionGrid>
      </Container>
    </RecognitionSection>
  )
}

export default Recognition
