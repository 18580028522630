import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Container } from "components/Layout/styled"
import {
  TechnologieItem,
  TechnologiesGrid,
  TechnologiesSection,
  TechnologiesTitle,
  TechnologyItemTitle,
} from "./styled"

const Technologies = ({ technologies: { sectionTitle, technologies } }) => {
  return (
    <TechnologiesSection>
      <Container>
        <TechnologiesTitle
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="50"
          data-sal-easing="ease"
        >
          {sectionTitle}
        </TechnologiesTitle>

        <TechnologiesGrid>
          {technologies.map(({ id, img, title }) => (
            <TechnologieItem
              key={id}
              data-sal="slide-up"
              data-sal-duration="600"
              data-sal-delay="50"
              data-sal-easing="ease"
            >
              <div>
                <GatsbyImage image={getImage(img)} alt="technology" />

                <TechnologyItemTitle>{title}</TechnologyItemTitle>
              </div>
            </TechnologieItem>
          ))}
        </TechnologiesGrid>
      </Container>
    </TechnologiesSection>
  )
}

export default Technologies
