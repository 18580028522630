import styled from "styled-components"
import { SectionTitle } from "styles"

export const TechnologiesSection = styled.div`
  padding: 90px 0 120px 0;
  background-color: #11100f;
`

export const TechnologiesTitle = styled(SectionTitle)`
  color: #fff;
  margin-bottom: 50px;

  @media (max-width: 1600px) {
    margin-bottom: 30px;
  }
`

export const TechnologiesGrid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));

  @media (max-width: 1439px) {
    grid-template-columns: repeat(auto-fit, 200px);
    justify-content: center;
  }
`

export const TechnologieItem = styled.div`
  border: 1px solid hsla(240, 7%, 71%, 0.3);
  border-radius: 12px;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  img {
    height: 55px;
    overflow: visible;
  }

  & > div {
    transition: transform 0.2s ease;
    will-change: transform;
  }

  :hover {
    & > div {
      transform: scale(1.1);
      will-change: transform;
    }
  }
`

export const TechnologyItemTitle = styled.p`
  color: #fff;
  font-size: 22px;
  line-height: 1.27;
  font-weight: 600;
  margin-top: 24px;
`
