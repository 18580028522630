export const opacities = {
  0: {
    top: {
      stop1: 1,
      stop2: 0.57,
      stop3: 0.28,
      stop4: 0.06,
    },
    bottom: {
      stop1: 1,
      stop2: 0.57,
      stop3: 0.28,
      stop4: 0.06,
    },
  },
  1: {
    top: {
      stop1: 0.12,
      stop2: 1,
      stop3: 0.5,
      stop4: 0.14,
    },
    bottom: {
      stop1: 0.12,
      stop2: 1,
      stop3: 0.5,
      stop4: 0.14,
    },
  },
  2: {
    top: {
      stop1: 0.1,
      stop2: 0.25,
      stop3: 1,
      stop4: 0.26,
    },
    bottom: {
      stop1: 0.1,
      stop2: 0.25,
      stop3: 1,
      stop4: 0.26,
    },
  },
  3: {
    top: {
      stop1: 0.02,
      stop2: 0.2,
      stop3: 0.4,
      stop4: 1,
    },
    bottom: {
      stop1: 0.02,
      stop2: 0.2,
      stop3: 0.4,
      stop4: 1,
    },
  },
}
