import styled from "styled-components"
import { SectionTitle } from "styles"

export const EngagementSection = styled.div`
  padding: 15vh 6vw;
  background-color: #11100f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const EngagementTitle = styled(SectionTitle)`
  color: #fff;
  text-align: center;
  max-width: 800px;
`

export const EngagementGrid = styled.div`
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 40px;

  @media (max-width: 1024px) {
    column-gap: 20px;
    row-gap: 60px;
    max-width: 700px;
  }
`

export const EngagementItemWrapper = styled.div`
  flex: 0 0 25%;

  @media (max-width: 1024px) {
    flex: 0 0 48%;
  }

  @media (max-width: 580px) {
    flex: 0 0 100%;
    max-width: 300px;
  }
`

export const EngagementItemInner = styled.div`
  padding: 70px 40px;
  transition: background 1s;
  border-radius: 10px;
  margin: -18px 0;
  width: 100%;

  @media (max-width: 1600px) {
    padding: 35px 25px;
  }

  @media (max-width: 1300px) {
    padding: 23px 20px;
  }

  @media (max-width: 1024px) {
    background: #37383a;
    padding: 20px 17px;
  }
`

export const EngagementImageWrapper = styled.div`
  width: 72px;
  height: 72px;
  margin: 0 auto;
  margin-bottom: 30px;
  height: 0;
  opacity: 0;
  transition: height 0.5s, opacity 0.2s ease-out;

  @media (max-width: 1300px) {
    width: 64px;
    margin-bottom: 15px;
  }

  @media (max-width: 1024px) {
    opacity: 1;
    height: 64px;
  }
`

export const EngagementItemTitle = styled.h4`
  color: #fff;
  line-height: 1.66;
  font-size: 36px;
  font-weight: 600;
  margin: 0 0 30px 0;

  @media (max-width: 1600px) {
    font-size: 28px;
    line-height: 1.5;
  }

  @media (max-width: 1300px) {
    font-size: 22px;
    line-height: 1.4;
    margin: 0 0 15px 0;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

export const EngagementItemText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 2.1;
  color: #86868b;

  @media (max-width: 1600px) {
    line-height: 1.5;
  }

  @media (max-width: 1300px) {
    font-size: 16px;
  }
`

export const EngagementItem = styled.div`
  transition: background 1s;
  padding: 0 26px;
  border-radius: 10px;
  height: 510px;
  display: flex;
  ${props =>
    props.isActive &&
    `
    background: #1f2022;
    & ${EngagementImageWrapper} {
      opacity: 1;
      height: 72px;

      @media (max-width: 1300px) {
      height: 64px;
      }
    }
    ${EngagementItemInner} {
      background: #37383a;
    }
  `}

  @media (max-width: 1600px) {
    height: 400px;
    padding: 0 18px;
  }

  @media (max-width: 1300px) {
    height: 290px;
    padding: 0 12px;
  }

  @media (max-width: 1024px) {
    background: #1f2022;
    height: 250px;
  }

  @media (max-width: 580px) {
    height: 225px;
  }
`
