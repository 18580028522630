import styled from "styled-components"

export const Intro = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #050203;
    z-index: -2;
  }

  canvas {
    width: 100% !important;
  }

  & > div {
    position: static !important;
  }

  @media (max-width: 568px) {
    button {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    button {
      font-size: 17px;
      padding: 12px 24px;
    }
  }
`

export const Title = styled.h1`
  font-size: 77px;
  line-height: 1.4;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 32px;
  color: #fff;

  & span {
    // background: #927ca8;
    border-radius: 6px;
    padding: 7px 14px;
    line-height: 1;
    display: inline-block;
    opacity: 0.97;
  }

  @media (max-width: 1024px) {
    font-size: 50px;
    margin-bottom: 20px;
  }
`

export const Subtitle = styled.div`
  & > h2 {
    font-size: 22px;
    max-width: 800px;
    color: #fff;
    font-weight: 400;
    margin: 7px 0;
    @media (max-width: 1024px) {
      font-size: 20px;
    }

    @media (max-width: 425px) {
      font-size: 18px;
    }
  }
`

export const TitleWrapper = styled.div`
  position: relative;
  display: inline-flex;
  padding-bottom: 50px;
`

export const Stack = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-top: 32px;

  @media (max-width: 568px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`

export const ClutchWidget = styled.div``

export const SvgLine = styled.svg`
  position: absolute;
  bottom: -15%;
  right: -50%;
  width: 30px;
  z-index: -1;
`

export const TitleCaption = styled.span`
  display: inline-block;
  font-size: 14px;
  color: #fff;
`
export const ContactUsButton = styled.button`
  border-radius: 40px;
  padding: 15px 30px;
  font-size: 22px;
  // background: linear-gradient(to right, #ad5389, #3c1053);
  background: #412536;
  color: #fff;

  display: flex;
  align-items: center;
  gap: 17px;
  & img {
    margin: 0px;
  }
`

export const AnimationSection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
`
export const Subbtitle = styled.div`
  left: 0;
`

export const HighLight = styled.span`
  position: relative;
  color: #fff;
  letter-spacing: 4px;
  text-shadow: 0 0 11px #f000ff;
  &::after {
    content: "Fly";
    position: absolute;
    top: 0;
    left: 0;
    color: #392152;
    filter: blur(19px);
    z-index: -1;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #8c30ce;
    opacity: 0.1;
    filter: blur(40px);
    z-index: -2;
  }
  & span {
    padding: 0;
    animation: blink linear infinite 3s;
  }

  @keyframes blink {
    78% {
      color: inherit;
      text-shadow: inherit;
    }
    79% {
      color: #333;
    }
    80% {
      text-shadow: none;
    }
    81% {
      color: inherit;
      text-shadow: inherit;
    }
    82% {
      color: #333;
      text-shadow: none;
    }
    83% {
      color: inherit;
      text-shadow: inherit;
    }
    92% {
      color: #333;
      text-shadow: none;
    }
    92.5% {
      color: inherit;
      text-shadow: inherit;
    }
  }
`
