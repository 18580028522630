import React, { useState } from "react"

import { FadeIn } from "components"
import ProgressBar from "./ProgressBar"
import {
  EngagementGrid,
  EngagementImageWrapper,
  EngagementItem,
  EngagementItemInner,
  EngagementItemText,
  EngagementItemTitle,
  EngagementItemWrapper,
  EngagementSection,
  EngagementTitle,
} from "./styled"

const Engagement = ({ engagement: { sectionTitle, engagementItems } }) => {
  const [activeEngagementItem, setActiveEngagementItem] = useState()

  return (
    <EngagementSection>
      <FadeIn>
        <EngagementTitle>{sectionTitle}</EngagementTitle>
      </FadeIn>
      <FadeIn>
        <EngagementGrid>
          {engagementItems.map(({ title, desc, img: { file }, id }, index) => (
            <EngagementItemWrapper key={id}>
              <EngagementItem
                onMouseEnter={() => setActiveEngagementItem(index)}
                isActive={activeEngagementItem === index}
              >
                <EngagementItemInner>
                  <EngagementItemTitle>{title}</EngagementItemTitle>

                  <EngagementImageWrapper>
                    <img src={file.url} alt={title} />
                  </EngagementImageWrapper>

                  <EngagementItemText>{desc}</EngagementItemText>
                </EngagementItemInner>
              </EngagementItem>
            </EngagementItemWrapper>
          ))}
        </EngagementGrid>

        <ProgressBar activeEngagementItem={activeEngagementItem} />
      </FadeIn>
    </EngagementSection>
  )
}

export default Engagement
